// src/components/organisms/LoginForm/index.tsx
import React, { Dispatch, SetStateAction, useState } from 'react';
import LoginContainer from './LoginContainer';
import ForgoutPassword from './ForgoutPassword';
import CodeValidation from './CodeValidation';
import ChangePassword from './ChangePassword';


interface LoginFormProps {
    setStep: Dispatch<SetStateAction<number>>;
}


const LoginForm: React.FC<LoginFormProps> = ({ setStep }) => {
    const [recoverStep, setRecoverStep] = useState(0);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");

    return (
        <div className="flex flex-col items-center justify-center h-full">
            <div className="flex flex-col items-center justify-center h-full">
                <div className="flex flex-col items-center pb-6">
                    <img src="/assets/logo.png" alt="Logo" style={{ width: '150px' }} />
                </div>

                {recoverStep === 0 &&
                    <LoginContainer setStep={setStep} setRecoverStep={setRecoverStep} />
                }

                {recoverStep === 1 &&
                    <ForgoutPassword setStep={setRecoverStep} setEmail={setEmail} />
                }

                {recoverStep === 2 &&
                    <CodeValidation setStep={setRecoverStep} setToken={setToken} />
                }

                {recoverStep === 3 &&
                    <ChangePassword setStep={setRecoverStep} email={email} token={token} />
                }
            </div>

            <div><p className="pb-4">Stoom 2024 © Todos os Direitos reservados</p></div>
        </div>
    );
};

export default LoginForm;