import { Button, Divider, Drawer } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';
import customerStore from '../../../stores/customerStore';
import { formatCurrency, inversionValueStatusClient, removeSpecialCharacters } from '../../../utils/functions';
import { MultipleRule, Status } from '../../../utils/selects';
import SelectComponent from '../../atoms/SelectComponent';
import { formatCep, formatPhone } from '../../../utils/TextFormatters';
import { formatDateAndTime } from '../../../utils/dateFunctions';

export const statusOptions = [
    {
        value: 'PENDING',
        label: 'Pendente'
    },
    {
        value: 'ACCEPTED',
        label: 'Aprovado'
    },
    {
        value: 'REJECTED',
        label: 'Reprovado'
    }
];


interface DescriptionItemProps {
    title: string;
    content: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
    <div className="flex gap-1 pb-2">
        <p className="inline-block pr-2 text-gray-500 font-normal">{title}:</p>
        <p className="font-semibold text-zinc-800 font-medium">{content}</p>
    </div>
);

interface ModalClienteProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    customer: any;
    notViewClientUnitsActive?: boolean;
}

const ModalCliente: React.FC<ModalClienteProps> = observer(({ isModalOpen, notViewClientUnitsActive, setIsModalOpen, customer }) => {
    const { defaultparams } = customerStore;

    const handleSubmit = async (values: any) => {
        const dataCustomer = {
            clientUnitsActive: values?.clientUnitsActive?.value,
            active: values?.active?.value,
            status: inversionValueStatusClient(customer.status).toUpperCase(),
            deleted: customer.deleted,
            user: {
                uuid: customer.user.uuid,
                username: customer.user.username,
                email: customer.user.email,
            },
            clientExternal: customer.clientExternal,
            firstName: customer.firstName,
            lastName: customer.lastName,
            entity: customer.entity,
            birthdate: customer.birthdate,
            maritalStatus: customer.maritalStatus,
            gender: customer.gender,
            municipalRegistration: customer.municipalRegistration,
            sms: customer.sms,
            document: removeSpecialCharacters(customer.document),
            exempt: customer.exempt,
            fraudster: customer.fraudster,
            stateRegistration: customer.stateRegistration,
            nickname: customer.firstName,
            phone: customer.phone,
            mobile: customer.mobile,
            customerType: {
                uuid: customer?.customerType?.uuid,
                name: customer?.customerType?.name,
            },
            mainAddress: {
                '@class': 'br.com.stoom.kernel.model.persistence.Address',
                uuid: customer?.mainAddress?.uuid,
                created: customer?.mainAddress?.created,
                modified: customer?.mainAddress?.modified,
                active: customer?.mainAddress?.active,
                deleted: customer?.mainAddress?.deleted,
                idAddress: customer?.mainAddress?.idAddress,
                street: customer?.mainAddress?.street,
                number: customer?.mainAddress?.number,
                block: customer?.mainAddress?.block,
                zipCode: customer?.mainAddress?.zipCode,
                city: customer?.mainAddress?.city,
                state: customer?.mainAddress?.state,
                addressComplement: customer?.mainAddress?.addressComplement,
                country: customer?.mainAddress?.country,
                mainAddress: customer?.mainAddress?.mainAddress,
                billingAddress: customer?.mainAddress?.billingAddress,
            },
            billingAddress: {
                '@class': 'br.com.stoom.kernel.model.persistence.Address',
                uuid: customer?.billingAddress?.uuid,
                created: customer?.billingAddress?.created,
                modified: customer?.billingAddress?.modified,
                active: customer?.billingAddress?.active,
                deleted: customer?.billingAddress?.deleted,
                idAddress: customer?.billingAddress?.idAddress,
                street: customer?.billingAddress?.street,
                number: customer?.billingAddress?.number,
                block: customer?.billingAddress?.block,
                zipCode: customer?.billingAddress?.zipCode,
                city: customer?.billingAddress?.city,
                state: customer?.billingAddress?.state,
                addressComplement: customer?.billingAddress?.addressComplement,
                country: customer?.billingAddress?.country,
                mainAddress: customer?.billingAddress?.mainAddress,
                billingAddress: customer?.billingAddress?.billingAddress,
            },
            newsletter: customer.newsletter,
        };

        const response = await customerStore.updateMulitpleCustomer(dataCustomer, customer?.uuid);
        await customerStore.getList(defaultparams);
        // changeStatusCustomer(values);

        if (!response.error) {
            setIsModalOpen(false);
        }
    };

    const initialValuesForm = {
        clientUnitsActive: { value: customer?.clientUnitsActive, label: '' },
        active: { value: customer?.active === true ? 'true' : 'false', label: '' },
    };

    return (
        <Formik initialValues={initialValuesForm} onSubmit={async (values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
            setIsModalOpen(false);
        }}
            enableReinitialize>
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Informações Cliente"
                    width={700}
                    onClose={() => setIsModalOpen(false)}
                    open={isModalOpen}
                    footer={
                        <div className="flex gap-4 justify-end">
                            {!notViewClientUnitsActive &&
                                <>
                                    <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                                    <Button type="primary" onClick={submitForm}>
                                        Atualizar
                                    </Button>
                                </>
                            }
                        </div>
                    }
                >
                    <Form>
                        {!notViewClientUnitsActive &&
                            <>
                                <div className="flex gap-2 w-full">
                                    <SelectComponent size="middle" label="Ativo" name="active" options={Status} />
                                    <SelectComponent size="middle" label="Troca de Unidade" name="clientUnitsActive" options={MultipleRule} />
                                </div>
                                <Divider />
                            </>
                        }
                        <p className="pb-4 text-base text-primary-color">Dados de Contato</p>

                        <div>
                            <DescriptionItem title="Nome Fantasia" content={customer?.firstName} />
                        </div>

                        <div>
                            <DescriptionItem title="Razão Social" content={customer?.corporateName} />
                        </div>

                        <div>
                            <DescriptionItem title="Nome de Contato" content={customer?.contactName} />
                        </div>

                        <div className="grid grid-cols-2 gap-4" >
                            <DescriptionItem title="CNPJ" content={customer?.document} />
                            <DescriptionItem title="Status do Cadastro" content={customer?.status} />
                        </div>

                        <div className="grid grid-cols-2 gap-4" >
                            <DescriptionItem title="Celular" content={formatPhone(customer?.mobile)} />
                            <DescriptionItem title="Telefone Fixo" content={formatPhone(customer?.phone)} />
                        </div>

                        <div>
                            <DescriptionItem title="Email" content={customer?.user?.email} />
                        </div>

                        <div>
                            <DescriptionItem title="Email Nota" content={customer?.emailNf} />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="Número com Whatsapp?" content={customer?.mobileWhatsapp ? "Sim" : "Não"} />
                        </div>

                        <Divider />
                        <p className="pb-4 text-base text-primary-color">Outras Informações</p>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="Canal" content={customer?.subChannel?.channel?.name} />
                            <DescriptionItem title="SubCanal" content={customer?.subChannel?.name} />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="Centro" content={customer?.center} />
                            <DescriptionItem title="Tabela de preço" content={customer?.tableId} />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="Troca de Unidade" content={customer?.clientUnitsActive === "HE" ? "Ativo" : "Inativo"} />
                            <DescriptionItem title="Código BP" content={customer?.clientExternal} />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="Grupo Clientes" content={customer?.clientGroup} />
                            <DescriptionItem title="Classe de Risco" content={customer?.riskClass} />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="Inscrição Estadual" content={customer?.stateRegistration} />
                            <DescriptionItem title="Matrícula Vendedor" content={customer?.seller} />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="Data aceite de Políticas" content={formatDateAndTime(customer?.acceptTermsDate)} />
                            <DescriptionItem title="IP Aceite Políticas" content={customer?.clientIpAddress} />
                        </div>

                        <Divider />
                        <p className="pb-4 text-base text-primary-color">Dados de Pagamento</p>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="Forma de Pagamento" content={customer?.paymentForm} />
                            <DescriptionItem title="Prazo de Pagamento" content={customer?.paymentDeadline} />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="Valor Mínimo" content={formatCurrency(customer?.minimumValue)} />
                            <DescriptionItem title="Valor Máximo" content={formatCurrency(customer?.limitValue)} />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <DescriptionItem title="CodPrazo Pagamento" content={customer?.paymentDeadlineCode} />
                        </div>

                        <Divider />
                        <p className="pb-4 text-base text-primary-color">Dados Comercial</p>

                        <div>
                            <DescriptionItem title="Contato Comercial" content={customer?.businessContact} />
                        </div>

                        <div className="grid grid-cols-2 gap-4" >
                            <DescriptionItem title="Telefone Comercial" content={customer?.phoneCommercial} />
                            <DescriptionItem title="Celular Comercial" content={customer?.cellphoneCommercial} />
                        </div>

                        <Divider />
                        <p className="pb-4 text-base text-primary-color">Dados Financeiro</p>

                        <div>
                            <DescriptionItem title="Contato Financeiro" content={customer?.contactFinancial} />
                        </div>

                        <div className="grid grid-cols-2 gap-4" >
                            <DescriptionItem title="Telefone Financeiro" content={formatPhone(customer?.phoneFinancial)} />
                            <DescriptionItem title="Celular Financeiro" content={formatPhone(customer?.cellphoneFinancial)} />
                        </div>

                        <Divider />
                        <p className="pb-4 text-base text-primary-color">Endereço de Cobrança</p>

                        <div>
                            <DescriptionItem title="Endereço" content={`${customer?.billingAddress?.street} - ${customer?.billingAddress?.number}`} />
                        </div>

                        <div>
                            <DescriptionItem title="Bairro" content={customer?.billingAddress?.block} />
                        </div>

                        <div className="grid grid-cols-2 gap-4" >
                            <DescriptionItem title="Cidade" content={customer?.billingAddress?.city} />
                            <DescriptionItem title="Estado" content={customer?.billingAddress?.state} />
                        </div>

                        <div className="grid grid-cols-2 gap-4" >
                            <DescriptionItem title="Pais" content={customer?.billingAddress?.country} />
                            <DescriptionItem title="CEP" content={formatCep(customer?.billingAddress?.zipCode)} />
                        </div>

                        <div className="grid grid-cols-1">
                            <DescriptionItem title="Complemento" content={customer?.billingAddress?.addressComplement} />
                        </div>


                        <Divider />
                        <p className="pb-4 text-base text-primary-color">Endereço de Entrega</p>

                        <div >
                            <DescriptionItem title="Endereço" content={`${customer?.mainAddress?.street} - ${customer?.mainAddress?.number}`} />
                        </div>

                        <div>
                            <DescriptionItem title="Bairro" content={customer?.mainAddress?.block} />
                        </div>

                        <div className="grid grid-cols-2 gap-4" >
                            <DescriptionItem title="Cidade" content={customer?.mainAddress?.city} />
                            <DescriptionItem title="Estado" content={customer?.mainAddress?.state} />
                        </div>

                        <div className="grid grid-cols-2 gap-4" >
                            <DescriptionItem title="Pais" content={customer?.mainAddress?.country} />
                            <DescriptionItem title="CEP" content={formatCep(customer?.mainAddress?.zipCode)} />
                        </div>

                        <div className="grid grid-cols-1">
                            <DescriptionItem title="Complemento" content={customer?.mainAddress?.addressComplement} />
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
});

export default ModalCliente;
